<template>
  <!-- <v-app> -->
    <div>
      <!-- Menu::Start -->
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h3 class="font-weight-bold">Statistik Penduduk</h3>
          </div>
          <div class="col-md-6">
            <Card>
              <template v-slot:body>
                <h3 class="d-block font-weight-bold my-2">Usia Penduduk</h3>
                <AgeChart></AgeChart>
              </template>
            </Card>
          </div>
          <div class="col-md-6">
            <Card>
              <template v-slot:body>
                <h3 class="d-block font-weight-bold my-2">Jenis Kelamin</h3>
                <GenderChart></GenderChart>
              </template>
            </Card>
          </div>
          <div class="col-md-6">
            <Card>
              <template v-slot:body>
                <h3 class="d-block font-weight-bold my-2">Pendidikan</h3>
                <EducationChart></EducationChart>
              </template>
            </Card>
          </div>
          <div class="col-md-6">
            <Card>
              <template v-slot:body>
                <h3 class="d-block font-weight-bold my-2">Agama</h3>
                <ReligionChart></ReligionChart>
              </template>
            </Card>
          </div>
          <div class="col-md-12">
            <Card>
              <template v-slot:body>
                <h3 class="d-block font-weight-bold my-2">Pekerjaan</h3>
                <ProfessionChart></ProfessionChart>
              </template>
            </Card>
          </div>
          <div class="col-md-12">
            <h3 class="font-weight-bold">Statistik Sarana & Prasarana</h3>
          </div>
          <div class="col-md-6">
            <Card>
              <template v-slot:body>
                <h3 class="d-block font-weight-bold my-2"
                  >Sarana & Prasarana Desa</h3
                >
                <InfrastructureChart></InfrastructureChart>
              </template>
            </Card>
          </div>
          <div class="col-md-6">
            <Card>
              <template v-slot:body>
                <h3 class="d-block font-weight-bold my-2">Rumah Ibadah</h3>
                <PrayPlaceChart></PrayPlaceChart>
              </template>
            </Card>
          </div>
          <div class="col-md-12">
        <h4 class="font-weight-bold">Statistik Usaha Desa</h4>
      </div>
      <div class="col-md-6">
        <Card>
          <template v-slot:body>
            <span class="d-block font-weight-bold my-2"
              >Komoditas Usaha Desa</span
            >
            <CommodityChart></CommodityChart>
          </template>
        </Card>
      </div>
      <div class="col-md-6">
        <Card>
          <template v-slot:body>
            <span class="d-block font-weight-bold my-2"
              >Status Kepemilikan Tempat Usaha</span
            >
            <StatusChart></StatusChart>
          </template>
        </Card>
      </div>
        </div>
      </div>
    </div>
    <!-- Menu::End -->
    <!-- </div> -->
  <!-- </v-app> -->
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { setConfiguration } from "@/core/services/jwt.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Card from "@/view/content/Card.vue";
import AgeChart from "@/view/components/statistic/AgeChart.vue";
import GenderChart from "@/view/components/statistic/GenderChart.vue";
import EducationChart from "@/view/components/statistic/EducationChart.vue";
import InfrastructureChart from "@/view/components/statistic/InfrastructureChart.vue";
import PrayPlaceChart from "@/view/components/statistic/PrayPlaceChart.vue";
import ReligionChart from "@/view/components/statistic/ReligionChart.vue";
import ProfessionChart from "@/view/components/statistic/ProfessionChart.vue";
import CommodityChart from "@/view/components/statistic/CommodityChart.vue";
import StatusChart from "@/view/components/statistic/StatusChart.vue";

export default {
  components: {
    Card,
    AgeChart,
    GenderChart,
    EducationChart,
    ReligionChart,
    InfrastructureChart,
    PrayPlaceChart,
    ProfessionChart,
    CommodityChart,
    StatusChart
  },
  data() {
    return {
      config: {},
      interval: null,
      time: null,

      modal2: false,
      hamlets: [],
    };
  },
  methods: {
    async getSetting() {
      let response = await module.get("settings-no-auth/1");
      console.log(
        "get setting no auth ============================================================================================"
      );
      if (response != null) {
        this.config = response;
        setConfiguration(JSON.stringify(response));
      }
    },
  },
  mounted() {
    this.getSetting();
    // this.getHamletHeadOption();
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat("en-GB", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);
  },
};
</script>

<style scoped>
.curved-header {
  height: 270px;
  width: 1280px;
  border: none;
  -webkit-box-shadow: inset 0px -1px 0px black;
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
}

.curved-footer {
  height: 230px;
  width: 1280px;
  border: none;
  /* -webkit-box-shadow: inset 0px -1px 0px black; */
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
}

.sample1 {
  border-radius: 0% 0% 150% 150%/0% 0% 70% 70%;
  width: 100%;
}
.sample2 {
  border-radius: 70% 70% 0% 0%/30% 30% 100% 100%;
  position: absolute;
  bottom: -5px;
  width: 100%;
}

.form-control-lg {
  height: 70px;
}
</style>

<style scoped>
.vue-treeselect--single .vue-treeselect__input-container {
  font-size: inherit;
  height: 70px !important;
}

.vue-treeselect--single .vue-treeselect__input {
  width: 100%;
  height: 70px !important;
}


</style>
